import * as Types from '../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTaskForTaskModalQueryVariables = Types.Exact<{
  accountListId: Types.Scalars['ID']['input'];
  taskId: Types.Scalars['ID']['input'];
  includeComments: Types.Scalars['Boolean']['input'];
}>;

export type GetTaskForTaskModalQuery = { __typename?: 'Query' } & {
  task: { __typename?: 'Task' } & Pick<
    Types.Task,
    | 'id'
    | 'activityType'
    | 'subject'
    | 'location'
    | 'startAt'
    | 'completedAt'
    | 'result'
    | 'nextAction'
    | 'tagList'
    | 'notificationTimeBefore'
    | 'notificationType'
    | 'notificationTimeUnit'
  > & {
      contacts: { __typename?: 'ContactConnection' } & {
        nodes: Array<
          { __typename?: 'Contact' } & Pick<Types.Contact, 'id' | 'name'>
        >;
      };
      comments?: { __typename?: 'CommentConnection' } & Pick<
        Types.CommentConnection,
        'totalCount'
      >;
      user?: Types.Maybe<
        { __typename?: 'UserScopedToAccountList' } & Pick<
          Types.UserScopedToAccountList,
          'id' | 'firstName' | 'lastName'
        >
      >;
    };
};

export const GetTaskForTaskModalDocument = gql`
  query GetTaskForTaskModal(
    $accountListId: ID!
    $taskId: ID!
    $includeComments: Boolean!
  ) {
    task(accountListId: $accountListId, id: $taskId) {
      id
      activityType
      subject
      location
      startAt
      completedAt
      result
      nextAction
      tagList
      contacts(first: 25) {
        nodes {
          id
          name
        }
      }
      comments @include(if: $includeComments) {
        totalCount
      }
      user {
        id
        firstName
        lastName
      }
      notificationTimeBefore
      notificationType
      notificationTimeUnit
    }
  }
`;

/**
 * __useGetTaskForTaskModalQuery__
 *
 * To run a query within a React component, call `useGetTaskForTaskModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskForTaskModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskForTaskModalQuery({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *      taskId: // value for 'taskId'
 *      includeComments: // value for 'includeComments'
 *   },
 * });
 */
export function useGetTaskForTaskModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTaskForTaskModalQuery,
    GetTaskForTaskModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTaskForTaskModalQuery,
    GetTaskForTaskModalQueryVariables
  >(GetTaskForTaskModalDocument, options);
}
export function useGetTaskForTaskModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTaskForTaskModalQuery,
    GetTaskForTaskModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTaskForTaskModalQuery,
    GetTaskForTaskModalQueryVariables
  >(GetTaskForTaskModalDocument, options);
}
export type GetTaskForTaskModalQueryHookResult = ReturnType<
  typeof useGetTaskForTaskModalQuery
>;
export type GetTaskForTaskModalLazyQueryHookResult = ReturnType<
  typeof useGetTaskForTaskModalLazyQuery
>;
export type GetTaskForTaskModalQueryResult = Apollo.QueryResult<
  GetTaskForTaskModalQuery,
  GetTaskForTaskModalQueryVariables
>;
